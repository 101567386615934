import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material'
import Modal from '@mui/material/Modal'
import { useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'

// src
import './AskQuestion.scss'
import { GradiantBtn } from '../../assets/muiStyles/Button-style'
import colors from '../../Colors.scss'
import DeleteIcon from '../../SVG/global/DeleteIcon'
import ContextHelp from '../../components/ContextHelp/ContextHelp'
import {
  useDeleteQuestionMutation,
  useGetQuestionsListQuery,
  usePostQuestionMutation
} from '../../redux/api/askQuestionApi/askQuestionApi'
import { deleteBtn, inputStyle, style } from '../../assets/muiStyles/askQuestion/askQuestionMui'
import ThreeDotsIcon from '../../SVG/global/ThreeDotsIcon'
// import QuestionIcon from '../../SVG/global/QuestionIcon'

// custom SFS
import QuestionsIcon from '../../SVG/custom/QuestionsIcon'

const AskQuestion = () => {
  // hooks
  const location = useLocation()

  // state
  const slot_id = location.state !== null ? location.state.id : 0
  const ref = location.state !== null ? location.state.referrer : ''
  const [referrer, setReferrer] = useState(ref)
  const [slotId, setSlotId] = useState(slot_id !== null ? slot_id : 0)
  const [deleteId, setDeleteId] = useState(0)
  const [loader, setLoader] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [hover, setHover] = useState(false)
  const [postQuestionText, setPostQuestionText] = useState('')
  const [loadbtn, setLoadBtn] = useState(true)
  const [modal, setModal] = useState(false)
  const postQuestionTextBoolean = postQuestionText.length > 999

  const { t, i18n } = useTranslation()

  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)

  // api
  const { data: questions, isLoading, isFetching } = useGetQuestionsListQuery(eventId)
  const [postQuestion] = usePostQuestionMutation()
  const [deleteQuestion] = useDeleteQuestionMutation()

  // mui
  const classesInput = inputStyle()
  const paperPopupDelete = deleteBtn()

  const handleDeleteQuestion = async () => {
    setModal(false)
    setAnchorEl(null)
    deleteQuestion({ eventId, deleteId }).then(() => {
      toast(
                <div style={{ display: 'flex', gap: '10px' }}>
                    <DeleteIcon />
                    <p>{t('pages.askquestionpage.question-deleted')}</p>
                </div>,
                {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                }
      )
    })
    setLoader(false)
  }

  const PostQuestionAPI = async () => {
    setLoader(true)
    // console.log('ref' + referrer)
    // console.log('slot_id' + slot_id)
    if (referrer === 'agenda') {
      postQuestion(
        {
          eventId,
          body: {
            question: postQuestionText,
            slot_id: slotId
          }
        }
      ).unwrap().then(value => {
        if (value) {
          setPostQuestionText('')
          setSlotId(0)
          setReferrer('')
        }
      })
    } else if (ref === 'poster') {
      postQuestion(
        {
          eventId,
          body: {
            question: postQuestionText,
            poster_id: slotId
          }
        }).unwrap().then(value => {
        if (value) {
          setPostQuestionText('')
          setSlotId(0)
          setReferrer('')
        }
      })
    } else {
      postQuestion(
        {
          eventId,
          body: {
            question: postQuestionText
          }
        }).unwrap().then(value => {
        if (value) {
          setPostQuestionText('')
        }
      })
    }
    setLoader(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    PostQuestionAPI()
  }

  useEffect(() => {
    if (loadbtn) {
      if (postQuestionText.length > 0) setLoadBtn(false)
    } else {
      if (postQuestionText.length === 0) setLoadBtn(true)
    }
  }, [postQuestionText])

  const scrollToBottom = (node) => {
    node[0].scrollTop = node[0].scrollHeight
  }

  useEffect(() => {
    const element = document.getElementsByClassName('question-cards-main')
    scrollToBottom(element)
  }, [questions])

  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })

  return (
        <>
            <div className="question-main">
                <div className="question-inside">
                    <div className="title-main">
                        <div className="title">
                            <QuestionsIcon width='30' height='30' color={colors.color_sidebar_icon}/>
                            <p>
                                <span className="help_span"
                                      onClick={(e) => {
                                        setShowModalHelper({
                                          open: !showModalHelper.open,
                                          event: e.currentTarget
                                        })
                                      }}>
                                {/* <QuestionsIcon color={colors.color_sidebar_icon} width='20' height='20'/> */}
                                    {t('sidebar.questions')}
                                    {showModalHelper && <ContextHelp helpText={{
                                      id: 3,
                                      what: t('pages.askquestionpage.context-help-event-moderator')
                                    }} open={showModalHelper.open} anchorEl={showModalHelper.event}
                                                                     setShowModalHelper={setShowModalHelper}/>}
                                </span>
                            </p>

                            {/*          */}
                            {/*          <p> */}
                            {/*          <FormattedMessage id="ask-questions" defaultMessage="Ask Questions"/>&nbsp; */}
                            {/*          <span */}
                            {/*      onClick={(e) => { */}
                            {/*        setShowModalHelper({ */}
                            {/*          open: !showModalHelper.open, */}
                            {/*          event: e.currentTarget */}
                            {/*        }) */}
                            {/*      }}> */}
                            {/*  <QuestionsIcon color={colors.color_sidebar_icon} width='20' height='20'/> */}
                            {/*  {showModalHelper && <ContextHelp */}
                            {/*      helpText={{ */}
                            {/*        id: 3, */}
                            {/*        what: <FormattedMessage id='question-helper' defaultMessage='Question box at different sections. Directly address speaker, chair, author or ECC-App moderator.'/> */}
                            {/*      }} */}
                            {/*      open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>} */}
                            {/* </span></p> */}
                        </div>
                    </div>
                    <div className="question-cards-main">
                        {isLoading && isFetching && loader && <CircularProgress className='circularProgress'/>}
                        {!isLoading && !isFetching && !loader &&
                            questions.map((question) => {
                              return (
                                    <div key={question.id}>
                                        <div
                                            onMouseEnter={() => setHover(true)}
                                            onMouseLeave={() => setHover(false)}
                                            className="question"
                                        >
                                            <Popover
                                                style={{ boxShadow: '2px 3px 6px rgba(0, 0, 0, 0.1)' }}
                                                anchorOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'right'
                                                }}
                                                transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'right'
                                                }}
                                                id={anchorEl ? 'simple-popover' : undefined}
                                                className={`${paperPopupDelete.border} delete-popover`}
                                                open={Boolean(anchorEl)}
                                                anchorEl={anchorEl}
                                                onClose={() => setAnchorEl(null)}
                                                sx={{ borderRadius: '16px' }}
                                            >
                                                <div
                                                    className="question-delete-main delete-popover-popup"
                                                    onClick={() => setModal(true)}
                                                >
                                                    <p className="question-delete-text ">{t('delete')}</p>
                                                    <DeleteIcon/>
                                                </div>
                                            </Popover>
                                            {question.writable
                                              ? (
                                                    <p
                                                        className="dot"
                                                        onClick={(e) => {
                                                          setDeleteId(
                                                            question.id
                                                          )
                                                          setAnchorEl(e.currentTarget)
                                                        }}
                                                    >
                                                        {hover
                                                          ? (
                                                                <ThreeDotsIcon />
                                                            )
                                                          : (
                                                                <span
                                                                    style={{
                                                                      cursor: 'none',
                                                                      color: colors.color_cards_background,
                                                                      width: '10px'
                                                                    }}
                                                                >
                            .
                          </span>
                                                            )}
                                                    </p>
                                                ) : (
                                                  ''
                                                )}
                                            <p className="question-text">{question?.question}</p>
                                            <div className="answered-time">
                                                {question.answered && (
                                                    <p className="answered">{t('pages.askquestionpage.answered')}
                                                    </p>
                                                )}{' '}
                                                <p>{t('pages.askquestionpage.sent-at')} {question.created_at.split('.')[0].split('T')[1]}</p>
                                            </div>
                                        </div>
                                    </div>
                              )
                            })}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="ask-question-field">
                                <TextField
                                    style={{ width: '100%' }}
                                    multiline
                                    className={classesInput.border}
                                    rows={window.screen.width <= 1024 ? 2 : 6}
                                    maxRows={window.screen.width <= 1024 ? 2 : 6}
                                    value={postQuestionText}
                                    onChange={(e) => setPostQuestionText(e.target.value)}
                                    variant="outlined"
                                    error={postQuestionTextBoolean}
                                    helperText={
                                        postQuestionText.length > 999
                                          ? t('pages.askquestionpage.character-limit')
                                          : '                             '
                                    }
                                    placeholder={t('pages.askquestionpage.write-your-question-here')}
                                    inputProps={{ maxLength: 1000 }}
                                    required
                                    InputProps={{
                                      style: {
                                        height: '400px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingBottom: '0px',
                                        paddingTop: '16px'
                                      }
                                    }}
                                />

                            <div className='asq-helper-absolute'
                                 onClick={(e) => {
                                   setShowModalHelper({
                                     open: !showModalHelper.open,
                                     event: e.currentTarget
                                   })
                                 }}>
                                <QuestionsIcon color={colors.color_sidebar_icon} width='20' height='20'/>
                                {showModalHelper && <ContextHelp helpText={{
                                  id: 3,
                                  what: t('pages.askquestionpage.context-help-platform-moderator')
                                }} open={showModalHelper.open} anchorEl={showModalHelper.event}
                                                                 setShowModalHelper={setShowModalHelper}/>}
                            </div>
                        </div>
                        {/* <p className="guideline"> */}
                        {/*    <FormattedMessage id="ask-question-guideline" defaultMessage="Please make sure to follow community guidelines while posting a */}
                        {/*    question, Thanks!"/> */}

                        <GradiantBtn
                            type="submit"
                            className={loadbtn && 'disb'}
                            loading={`${loader}`}
                            variant="contained"
                            size="large"
                            disabled={loadbtn}
                        >
                            {t('pages.askquestionpage.button-send-question')}
                        </GradiantBtn>
                    </form>
                </div>
            </div>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-box">
                    <DeleteIcon/>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('pages.askquestionpage.delete-question')}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t('pages.askquestionpage.are-you-sur-delete-question')}
                    </Typography>
                    <hr/>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            onClick={() => {
                              setAnchorEl(null)
                              setModal(false)
                            }}
                            color="primary"
                            className="modal-cancel-btn"
                        >
                            {t('pages.askquestionpage.cancel')}
                        </Typography>
                        <Typography
                            onClick={() => handleDeleteQuestion()}
                            style={{ color: 'red' }}
                            className="modal-delete-btn"
                        >
                            {t('pages.askquestionpage.delete')}
                        </Typography>
                    </div>
                </Box>
            </Modal>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                theme="colored"
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
  )
}
export default AskQuestion
