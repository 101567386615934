import React, { useEffect, useState } from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import { useNavigate } from 'react-router-dom'
import { CardActionArea, CardMedia, Select, Tab, Tabs, TextareaAutosize, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { toast, ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

// rsc
import { GradiantBtn } from '../../assets/muiStyles/Button-style'
import ChangePassword from '../../components/ChangePassword'
import SignInPage from '../SignInPage'
import './EditingProfilePage.scss'
import Edit from '../../SVG/pages/Chats/Edit'
// import noAvatar from '../../images/noAvatar.png'
import Global from '../../SVG/Presence/Global'
import OnSiteStatus from '../../SVG/Presence/OnSiteStatus'
import {
  useAddAvatarUserMutation,
  useChangeAvatarUserMutation,
  useGetDetailUserQuery,
  usePutDataUserAllMutation
} from '../../redux/api/userApi/userApi'
import { useGetCountriesQuery } from '../../redux/api/participtantApi/participantApi'
import { addLastUrl } from '../../redux/slices/globalValue/globalValue'
import { useTranslation } from 'react-i18next'
import { languageMap } from '../../i18n'

// SFS CUSTOM
import noAvatar from '../../images/sfs/noAvatar.png'

const EditingProfilePage = () => {
  // hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // redux
  const token = useSelector(state => state.authReducer.token)
  // state
  const [credentials, setCredentials] = useState({
    attendee_type: '',
    avatar: {
      download_url: '',
      title: '',
      width: 0,
      height: 0,
      avatar_id: 0
    },
    biography: '',
    city: '',
    country: '',
    email: '',
    first_name: '',
    full_name: '',
    id: 0,
    institution: '',
    job_title: '',
    last_name: '',
    sendbird_user_id: '',
    status_online: '',
    title: '',
    countryValue: '',
    preferred_language: ''
  })
  const [countryValue, setCountryValue] = useState('country')
  const [languageValue, setLanguageValue] = useState('')
  const [deleteAvatar, setDeleteAvatar] = useState(false)
  const [avatar, setAvatar] = useState(false)
  const [loader, setLoader] = useState(false)
  const [tabsValue, setTabsValue] = useState(false)
  const [inputProp] = useState({ value: true })

  const { t, i18n } = useTranslation()

  // api
  const [addAvatarUser] = useAddAvatarUserMutation()
  const [putDataUserAll] = usePutDataUserAllMutation()
  const { data: userDataFromDb } = useGetDetailUserQuery()
  const [changeAvatarUser] = useChangeAvatarUserMutation()
  const { data: countries } = useGetCountriesQuery()

  const availableLanguages = process.env.REACT_APP_AVAILABLE_LANGUAGES.split(',')

  useEffect(() => {
    if (userDataFromDb?.preferred_language) {
      setLanguageValue(userDataFromDb.preferred_language)
    }
  }, [userDataFromDb])

  useEffect(() => {
    i18n.changeLanguage(languageValue)
  }, [languageValue])

  // Change status
  const handleChangeTabs = (e, newValue) => {
    setTabsValue(newValue)
    setCredentials({ ...credentials, attendee_type: newValue })
  }

  // Set persons info
  const handleChange = async (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    dispatch(addLastUrl(window.location.href))
  }, [])
  // Get Country
  useEffect(() => {
    if (userDataFromDb) {
      setCredentials(userDataFromDb)
      setCountryValue(userDataFromDb?.country ? userDataFromDb?.country : 'country')
      setTabsValue(userDataFromDb?.attendee_type ? userDataFromDb?.attendee_type : false)
    }
  }, [userDataFromDb])
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (JSON.stringify(userDataFromDb) === JSON.stringify(credentials) && !avatar?.avatar_file && !deleteAvatar) {
      toast.info(t('pages.editingprofilepage.toast-info-edit-data'), { position: 'top-center' })
      setLoader(false)
    }

    if (!credentials.avatar && !deleteAvatar && avatar.avatar_file) {
      setLoader(true)
      addAvatarUser({ file: avatar?.avatar_file }).unwrap().then(value => {
        if (value?.id) {
          const userData = { ...credentials, avatar_id: value?.id }
          putDataUserAll(userData).unwrap().then(value => {
            if (value) {
              toast.success(t('pages.editingprofilepage.toast-info-change-success'), { position: 'top-center' })
              setAvatar({ ...avatar, avatar_file: null })
            }
          }).then(() => {
            localStorage.setItem('language', languageValue)
          })
        }
      })
      setLoader(false)
    }

    if ((JSON.stringify(userDataFromDb) === JSON.stringify(credentials)) && deleteAvatar && !avatar.avatar_file) {
      setLoader(true)

      const userData = { ...credentials, avatar_id: null }
      putDataUserAll(userData).unwrap().then(value => {
        if (value) {
          toast.success(t('pages.editingprofilepage.toast-info-change-success'), { position: 'top-center' })
          setDeleteAvatar(false)
        }
      })

      setLoader(false)
    }

    if (credentials?.avatar && avatar?.avatar_file) {
      setLoader(true)

      if (avatar?.avatar_file) {
        changeAvatarUser({ file: avatar?.avatar_file }).unwrap().then(value => {
          const userData = { ...credentials, avatar_id: value?.id }
          putDataUserAll(userData).unwrap().then(value => {
            if (value) {
              toast.success(t('pages.editingprofilepage.toast-info-change-success'), { position: 'top-center' })
              setAvatar({ ...avatar, avatar_file: null })
            }
          })
        })
      }
      setLoader(false)
    }

    if (!avatar?.avatar_file && !deleteAvatar && !(JSON.stringify(userDataFromDb) === JSON.stringify(credentials))) {
      setLoader(true)
      putDataUserAll(credentials).unwrap().then(value => {
        if (value) {
          toast.success(t('pages.editingprofilepage.toast-info-change-success'), { position: 'top-center' })
        }
      })

      setLoader(false)
    }

    setLoader(false)
  }

  if (token) {
    return (

            <div className="editing-profile-main-container">
                <ToastContainer/>
                <div className="edit-profile-header">
                    <div className="arrow-back cursor-pointer" onClick={() => navigate(-1)}>
                        <ArrowBackOutlinedIcon/><span>{t('pages.editingprofilepage.back')}</span>
                    </div>
                    <h1>{t('pages.editingprofilepage.edit')}</h1>
                </div>

                <div className="edit-profile-wraper">
                    <div className="container-header">
                        <div className="container-introduce">
                            <p>
                                {t('pages.editingprofilepage.introduce')}
                            </p>
                            <span>
                                {t('pages.editingprofilepage.description')}
                            </span>
                        </div>

                        <hr/>
                        <form
                            onSubmit={handleSubmit}
                        >
                            <div className="editing-fields-container">
                                <div className="add-avatar-container">
                                    <div className="avatar-action-container">
                                        <div className="add-avatar">
                                            <FormControl
                                                sx={{
                                                  width: '100%',
                                                  height: '100%'
                                                }}
                                            >
                                                {((!credentials?.avatar && !avatar?.avatar_file) || deleteAvatar) &&
                                                    <div><img className="avatar"
                                                              src={noAvatar} alt="AvatarVeiw"/></div>
                                                }
                                                {deleteAvatar
                                                  ? <img src={noAvatar} alt="No avatar"/>
                                                  : credentials?.avatar?.download_url
                                                    ? <CardActionArea
                                                            sx={{
                                                              width: '100%',
                                                              height: '100%'
                                                            }}
                                                        >
                                                            <CardMedia
                                                                className="avatar-editing-profile"
                                                                accept="image/*"
                                                                component="img"
                                                                alt="avatar"
                                                                image={avatar?.avatar_url
                                                                  ? avatar?.avatar_url
                                                                  : credentials?.avatar?.download_url}
                                                                title="comp"
                                                            />
                                                        </CardActionArea>
                                                    : avatar?.avatar_url
                                                      ? <CardActionArea
                                                                sx={{
                                                                  width: '100%',
                                                                  height: '100%'
                                                                }}>
                                                                <CardMedia
                                                                    className="avatar-editing-profile"
                                                                    accept="image/*"
                                                                    component="img"
                                                                    alt="avatar"
                                                                    image={avatar?.avatar_url
                                                                      ? avatar?.avatar_url
                                                                      : credentials?.avatar?.download_url}
                                                                    title="comp"
                                                                />
                                                            </CardActionArea>
                                                      : ''}

                                            </FormControl>
                                            <div className="avatar-action">
                                                <div className="cursor-pointer"
                                                >
                                                    <div onClick={() => {
                                                      const input = document.createElement('input')
                                                      input.type = 'file'
                                                      input.accept = 'image/png, image/jpeg'
                                                      input.click()
                                                      input.addEventListener('change', (e) => {
                                                        const avatar = e.target.files[0]
                                                        setAvatar({
                                                          ...avatar,
                                                          avatar_file: e.target.files[0],
                                                          avatar_name: avatar.name,
                                                          avatar_url: URL.createObjectURL(e.target.files[0])
                                                        })
                                                        setDeleteAvatar(false)
                                                      })
                                                    }}>
                                                        <Edit width={21} height={21}/>
                                                    </div>
                                                </div>
                                                {/* <div className="cursor-pointer" */}
                                                {/*     onClick={() => { */}
                                                {/*       setDeleteAvatar(true) */}
                                                {/*     }} */}
                                                {/* > */}
                                                {/*    <Trash width={21} height={21}/> */}
                                                {/* </div> */}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="fields-container">
                                    <div className="field-tabs-container">
                                        <p>{t('pages.editingprofilepage.participant')}</p>
                                        <Tabs
                                            value={tabsValue}
                                            onChange={handleChangeTabs}

                                            sx={{
                                              '& .MuiTabs-indicator': { backgroundColor: '#4C6FFF' },
                                              '& .MuiTab-root': { color: '#6B6F7ABF' },
                                              '& .Mui-selected': { color: 'black' }
                                            }}
                                        >
                                            <Tab value="IN_PERSON" label={<p
                                                style={{ color: tabsValue === 'IN_PERSON' ? 'black' : '#6B6F7ABF' }}>
                                                <OnSiteStatus width={12} height={12}
                                                              color={tabsValue === 'IN_PERSON' ? '#4C6FFF' : '#6B6F7ABF'}
                                                /> {t('pages.editingprofilepage.on-site')}</p>
                                            }

                                                 sx={{ width: '50%' }}/>
                                            <Tab value="ONLINE" label={<p
                                                style={{ color: tabsValue === 'ONLINE' ? 'black' : '#6B6F7ABF' }}>
                                                <Global
                                                    width={12} height={12}
                                                    color={tabsValue === 'ONLINE' ? '#12B76A' : '#6B6F7ABF'}
                                                /> {t('pages.editingprofilepage.remote')}</p>
                                            }
                                                 sx={{ width: '50%' }}
                                            />
                                        </Tabs>
                                    </div>
                                    <div className="first-last-name-container ">
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                                sx={{
                                                  '& .MuiInputBase-input': {
                                                    height: 'auto'
                                                  }
                                                }}
                                                onChange={handleChange}
                                                placeholder={t('pages.editingprofilepage.first-name')}
                                                value={credentials?.first_name}
                                                type="text   "
                                                name="first_name"
                                            />
                                        </FormControl>
                                        <FormControl
                                            sx={{ width: '100%' }}>
                                            <TextField
                                                sx={{
                                                  '&.MuiInput-input': {
                                                    color: 'red'
                                                  }
                                                }}
                                                placeholder={t('pages.editingprofilepage.last-name')}
                                                onChange={handleChange}
                                                value={credentials?.last_name}
                                                type="text"
                                                name="last_name"
                                            />
                                        </FormControl>
                                    </div>
                                    <hr/>
                                    <div className="fields-2-container">
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                                fullwidth='true'

                                                placeholder={t('pages.editingprofilepage.title')}
                                                onChange={handleChange}
                                                value={credentials?.title}
                                                type="text"
                                                name="title"
                                            />
                                        </FormControl>

                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                                fullwidth='true'

                                                placeholder={t('pages.editingprofilepage.job-title')}
                                                onChange={handleChange}
                                                value={credentials?.job_title}
                                                type="text"
                                                name="job_title"
                                            />
                                        </FormControl>

                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                                fullwidth='true'

                                                placeholder={t('pages.editingprofilepage.city')}
                                                onChange={handleChange}
                                                value={credentials?.city}
                                                type="text"
                                                name="city"
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                                fullwidth='true'

                                                placeholder={t('pages.editingprofilepage.company')}
                                                onChange={handleChange}
                                                value={credentials?.institution}
                                                type="text"
                                                name="institution"
                                                variant="outlined"
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <Select
                                                defaultValue="none"
                                                fullwidth='true'
                                                onChange={handleChange}
                                                displayEmpty
                                                name="country"
                                                inputProps={!!inputProp.value}
                                                value={countryValue}
                                            >
                                                <MenuItem value='country' disabled>
                                                    {t('pages.editingprofilepage.country')}
                                                </MenuItem>
                                                {countries && countries?.map((countryValueOne) => (
                                                    <MenuItem
                                                        onClick={() => {
                                                          setCountryValue(countryValueOne.value)
                                                        }}
                                                        key={countryValueOne.label}
                                                        value={countryValueOne.value}
                                                    >{countryValueOne.label}</MenuItem>))}
                                            </Select>
                                        </FormControl>

                                        <FormControl>
                                            <Select
                                                defaultValue="none"
                                                fullwidth='true'
                                                onChange={handleChange}
                                                displayEmpty
                                                name="preferred_language"
                                                inputProps={!!inputProp.value}
                                                value={languageValue}
                                            >
                                                <MenuItem value='country' disabled>
                                                    {t('pages.editingprofilepage.language')}
                                                </MenuItem>
                                                {availableLanguages && availableLanguages?.map((value, i) => (
                                                    <MenuItem
                                                        onClick={() => {
                                                          setLanguageValue(value)
                                                        }}
                                                        key={i}
                                                        value={value}
                                                    >{languageMap[value]}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>
                                <div className="biography-container">
                                    <FormControl
                                        sx={{ height: '100%' }}
                                        fullwidth='true'
                                    >
                                        <TextareaAutosize
                                            fullwidth='true'

                                            style={{ height: '100%' }}
                                            placeholder={t('pages.editingprofilepage.biography')}
                                            onChange={handleChange}
                                            aria-hidden="false"
                                            multiline={'true'}
                                            minRows={17}
                                            maxRows={17}
                                            value={credentials?.biography}
                                            type="text"
                                            name="biography"
                                            variant="standard"
                                        />
                                    </FormControl>

                                    <GradiantBtn
                                        fullwidth='true'
                                        sx={{
                                          padding: '10px, 16px,  8px, 16px'
                                        }}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {loader
                                          ? <>{t('pages.editingprofilepage.saving')}</>
                                          : <>{t('pages.editingprofilepage.save')}</>}
                                    </GradiantBtn>
                                </div>

                            </div>

                            <hr/>
                        </form>
                        <div className="change-password-editing-container">
                            <ChangePassword/>
                        </div>
                    </div>
                </div>
            </div>
    )
  } else {
    return (
            <>
                <SignInPage/>
            </>
    )
  }
}

export default EditingProfilePage
